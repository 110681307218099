<!-- eslint-disable max-len -->
<template>
  <svg width="300" height="67" viewBox="0 0 300 67" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.3938 66.1146V55.5469H4.56991V66.1146H0V41.7272H4.56991V51.9916H16.3938V41.7272H20.9637V66.1146H16.3938Z"
      fill="var(--black-90)"
    />
    <path d="M122.617 66.1146V41.7272H127.187V66.1146H122.617Z" fill="var(--black-90)" />
    <path
      d="M163.873 45.3177H151.541V41.7272H170.8L157.489 62.5243H170.619V66.1146H150.525L163.873 45.3177Z"
      fill="var(--black-90)"
    />
    <path
      d="M202.657 41.287C210.31 41.287 216.186 46.8079 216.186 53.921C216.186 61.0341 210.419 66.555 202.657 66.555C194.931 66.555 189.128 61.0341 189.128 53.921C189.128 46.9097 194.931 41.287 202.657 41.287ZM202.657 62.626C207.844 62.626 211.58 58.8663 211.58 53.921C211.58 49.0098 207.844 45.2161 202.657 45.2161C197.47 45.2161 193.698 49.0098 193.698 53.921C193.698 58.8663 197.47 62.626 202.657 62.626Z"
      fill="var(--black-90)"
    />
    <path
      d="M255.881 66.4874L240.611 51.8889C240.212 51.55 239.776 51.1435 239.341 50.805V66.1142H234.844V41.3541H234.989L250.223 55.9188L251.493 57.0707V41.7274H255.99V66.4874H255.881Z"
      fill="var(--black-90)"
    />
    <path d="M300 41.7096H275.577V66.1769H300V41.7096Z" fill="#FE5000" />
    <path
      d="M296.979 55.6651C296.942 55.0972 296.792 54.5423 296.539 54.0327C296.286 53.523 295.934 53.0688 295.505 52.6963C295.084 52.3266 294.594 52.0444 294.064 51.866C293.533 51.6877 292.973 51.6167 292.414 51.6574C292.107 51.6775 291.803 51.7321 291.507 51.8201L293.894 47.8901H292.405L288.995 53.5233C288.565 54.1775 288.319 54.9361 288.285 55.7186C288.25 56.5012 288.427 57.2786 288.798 57.9684C289.168 58.6582 289.718 59.2346 290.39 59.6366C291.061 60.0386 291.828 60.2512 292.61 60.2518C292.711 60.2518 292.814 60.2483 292.917 60.241C293.506 60.2095 294.082 60.0552 294.607 59.7877C295.133 59.5203 295.597 59.1458 295.97 58.6882C296.667 57.8397 297.026 56.763 296.979 55.6651ZM292.848 59.0238C292.032 59.0807 291.227 58.8111 290.609 58.2741C290.151 57.8732 289.822 57.3454 289.664 56.7574C289.505 56.1693 289.524 55.5474 289.719 54.9701C289.913 54.3929 290.273 53.8862 290.755 53.5141C291.236 53.142 291.817 52.9211 292.423 52.8795C292.492 52.8745 292.56 52.8728 292.627 52.8728C293.405 52.8809 294.152 53.1786 294.723 53.7079C295.294 54.2373 295.648 54.9605 295.716 55.7369C295.77 56.5537 295.498 57.3584 294.96 57.9746C294.423 58.5908 293.663 58.9682 292.848 59.0238Z"
      fill="var(--black-90)"
    />
    <path
      d="M286.537 49.1201L287.282 47.8904H283.754L280.14 53.8505L281.049 53.3162C281.535 53.0069 282.098 52.8428 282.674 52.843C283.501 52.8419 284.296 53.1617 284.892 53.7353C285.18 54.0086 285.409 54.3379 285.565 54.7031C285.722 55.0683 285.802 55.4617 285.801 55.859C285.807 56.2764 285.73 56.6908 285.573 57.0776C285.416 57.4644 285.183 57.8156 284.888 58.1104C284.593 58.4053 284.242 58.6377 283.856 58.7938C283.469 58.95 283.056 59.0267 282.639 59.0194C282.565 59.0177 282.494 59.0095 282.417 59.0005L282.356 58.9932C282.272 58.9841 282.189 58.9742 282.11 58.9602C282.051 58.9486 281.993 58.9343 281.925 58.9171C281.834 58.894 281.743 58.8695 281.656 58.8392L281.533 58.7923C281.41 58.7437 281.29 58.6882 281.173 58.626C280.5 58.2392 279.991 57.6189 279.742 56.882C279.604 56.488 279.49 56.0859 279.401 55.6779L279.313 55.2566L278.411 56.7128L278.43 56.8169C278.58 57.6464 278.986 58.4078 279.591 58.9932C280.349 59.7397 281.353 60.1842 282.414 60.2436C282.499 60.2494 282.561 60.2503 282.562 60.2503H282.66C282.745 60.2503 282.829 60.2457 282.914 60.2366L283.001 60.2299C284.109 60.1706 285.152 59.6852 285.912 58.8747C286.672 58.0642 287.09 56.9913 287.079 55.8795C287.092 54.7544 286.659 53.6702 285.876 52.8645C285.11 52.0853 284.07 51.6366 282.979 51.6139L284.493 49.1096L286.537 49.1201Z"
      fill="var(--black-90)"
    />
    <path
      d="M128.546 0.000338754C97.443 -0.0871423 70.1921 16.7763 55.1012 42.0571C53.5623 41.5424 51.9499 41.2825 50.3276 41.2876C42.6021 41.2876 36.7986 46.9103 36.7986 53.9216C36.7986 61.0347 42.6021 66.5556 50.3276 66.5556C58.0897 66.5556 63.8565 61.0347 63.8565 53.9216C63.8598 51.7906 63.3096 49.6953 62.26 47.8418C61.2104 45.9882 59.6975 44.4401 57.8699 43.3495C69.3989 23.9316 88.2753 9.58329 110.559 4.34289C117.055 2.7731 123.716 1.99067 130.399 2.01239C158.285 2.09142 182.975 15.9123 198.384 37.1707C182.916 14.7616 157.43 0.0808213 128.546 0.000338754ZM50.3258 62.626C45.1391 62.626 41.367 58.8663 41.367 53.921C41.367 49.0098 45.1391 45.2161 50.3258 45.2161C55.5125 45.2161 59.2482 49.0098 59.2482 53.921C59.2482 58.8663 55.5125 62.626 50.3258 62.626Z"
      fill="#FE5000"
    />
    <path
      d="M95.3581 55.2759C97.956 54.2052 99.3788 51.97 99.3788 48.9306C99.3788 45.7 97.7421 41.8488 89.9435 41.8488H81.3958L88.3985 51.954H82.31V66.1609H86.7088V56.0131H90.8394L97.6507 66.1609H102.649L95.3581 55.2759ZM94.9797 48.9306C94.9797 50.4694 94.4599 51.2833 93.2001 51.6773L89.2863 45.907H91.0618C94.6782 45.907 94.9806 47.4677 94.9806 48.9312L94.9797 48.9306Z"
      fill="var(--black-90)"
    />
  </svg>
</template>
